<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">Add Branch</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <input
        type="file"
        ref="img"
        accept="image/*"
        class="hidden"
        v-on:change="uploadImg"
      />
      <div
        class="bg-gray-300 bg-opacity-30 px-5 py-20 cursor-pointer rounded-full my-5 mx-auto w-3/4 flex items-center justify-center"
        @click="$refs.img.click()"
        v-if="imgPlaceholder"
      >
        <CameraIcon />
      </div>
      <img
        :src="branch.branch_image"
        class="rounded-full w-3/4 h-auto my-5 mx-auto border border-primary p-2"
        v-else
      />
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="createNewBranch" class="text-left mt-10">
          <div class="mb-5">
            <label for="email" class="block mb-2">Branch Name</label>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Ikeja"
                v-model="branch.branch_name"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Branch Email</label>
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <input
                id="email"
                type="email"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="example@tos.com"
                v-model="branch.branch_email"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Branch Phone Number</label>
            <ValidationProvider
              name="phone"
              rules="required|length:11"
              v-slot="{ errors }"
            >
              <input
                id="phone"
                type="tel"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="070123123123"
                v-model="branch.branch_phone"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2">Branch Address</label>
            <ValidationProvider
              name="address"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                id="address"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="2239  Anthony Villlage"
                v-model="branch.branch_address"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="email" class="block mb-2"
              >Working with the Government Hospitals (DT/SN)</label
            >
            <ValidationProvider
              name="DT/SN"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                v-model="branch.branch_working_with_government_hospital"
                placeholder="True"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
              >
                <option :value="true">True</option>
                <option :value="false">False</option>
              </select>
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <button
            class="w-full mt-10 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            {{ submitting ? "Please wait..." : "Create Branch" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  uploadToCloudinary,
  createBranch,
  editBranch,
} from "@/services/branch";

export default {
  name: "",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
    CameraIcon: () => import("@/assets/icons/CameraIcon.vue"),
  },
  props: {
    branch_edit: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      branch: {
        branch_name: "",
        branch_email: "",
        branch_phone: "",
        branch_address: "",
        branch_image: "",
        branch_working_with_government_hospital: false,
      },
      submitting: false,
      imgPlaceholder: true,
    };
  },
  methods: {
    async createNewBranch() {
      this.submitting = true;
      try {
        if (this.branch_edit.branch_image) {
          await editBranch(this.branch_edit.id, this.branch);
        } else {
          await createBranch(this.branch);
        }
        this.submitting = false;
        this.$toast.success("New Branch created.");
        this.$emit("created");
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    async uploadImg(e) {
      const img = e.target.files[0];

      let formData = new FormData();
      formData.append("upload_preset", "branchImages");
      formData.append("folder", "tos-project");
      formData.append("file", img);
      try {
        const response = await uploadToCloudinary(formData);
        this.branch.branch_image = response.data.url;
        this.imgPlaceholder = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if (this.branch_edit.branch_image) {
      this.branch = this.branch_edit;
      this.imgPlaceholder = false;
    }
  },
};
</script>
